class Resource {
	constructor(data) {
		if (empty(data)) data = {}

		// generate a new resource_id if needed
		if (data.resource_id == 'new') this.resource_id = U.new_uuid()
		else if (!empty(data.resource_id)) this.resource_id = data.resource_id
		else {
			console.warn('Resource created with no resource_id')
			// this.resource_id = U.new_uuid()
		}

		// resource_template_id: if set, this resource is based on the resource that has resource_id == resource_template_id; see ResourceCollectionItem
		sdp(this, data, 'resource_template_id', '')

		sdp(this, data, 'agency_sanctioned', false)

		sdp(this, data, 'type', 'none', ['none', 'upload', 'html', 'website', 'document', 'video', 'interactive', 'safari', 'lti', 'resource_collection', 'collection_item', 'assessment', 'sparkl', 'google'])
		// a 'resource_collection' is an externally-defined collection -- e.g. a publisher's textbook resources, loaded via common cartridge

		sdp(this, data, 'url', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'long_description', '')
		sdp(this, data, 'lti_params', {})
		sdp(this, data, 'case_identifiers', [])		// used when importing common cartridges
		sdp(this, data, 'target_students', 'all', ['all', 'ese', 'adv'])

		// 'lp_category' isn't really about the lp; it's a legacy thing from HenryConnects. We should change this to an array of metadata values...
		// also, in Henry lp_category, `course_guidance` used to be `cross_unit`; update accordingly
		if (data.lp_category == 'cross_unit') {
			this.lp_category = 'course_guidance'
			this.cross_unit = true
		} else {
			sdp(this, data, 'lp_category', '')	// unit_planning, course_guidance, course_ebook, stem_resource, leader_resource
			sdp(this, data, 'cross_unit', false)	// set to true for resources that should be shown in all units of an LP
		}

		sdp(this, data, 'creator', 0)
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'mappings', [])	// this will be an array of values such as 'course-1234', 'grade-4', or 'subject-Math'
		sdp(this, data, 'family_avail', false)	// set to true for resources that should be shown to families (false by default)
		sdp(this, data, 'block_or_traditional', 'both', ['both', 'block', 'traditional'])	// some resources have to be limited to the "block" or "traditional" format
		sdp(this, data, 'todo', false)		// is this resource marked as a "todo" item for users (i.e. for professional development)?

		if (!empty(data.extensions)) sdp(this, data, 'extensions', {})

		// standards stored like lessons
		this.standards = []
		if (!empty(data.standards)) {
			// a bug, fixed on 6/17/2024, was causing uploaded-file resources with standards specified to have the standards param saved as, e.g., "[object Object],[object Object]"
			// so if data.standards is a string, skip it, and (below) if an incoming standard doesn't have an identifier (i.e. a CASE GUID), skip those too
			if (typeof(data.standards) == 'string') {
				console.warn(`data.standards is string for resource ${this.resource_id} [${this.description.substr(0,40)}]`)
			} else {
				for (let standard of data.standards) {
					if (empty(standard.identifier)) {
						console.warn(`skipping empty standard in resource ${this.resource_id} [${this.description.substr(0,40)}]`)
						continue
					}
					// for now at least we'll use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
					this.standards.push(new CASE_Item(standard))
				}
			}
		}
		
		sdp(this, data, 'stars_available', -1)	// this should be filled in for sparkl activities (?)

		if (!empty(data.lti_params)) {
			// some legacy HMH resources were saved without setting agency_sanctioned and/or teacher_facing properly, so...
			// default value for agency_sanctioned is true for lti links
			sdp(this, data, 'agency_sanctioned', true)

			// and if lti_params.custom_resource_url has "/teacher/" in it, default value for teacher_facing is true (otherwise false)
			if (!empty(data.lti_params.custom_resource_url) && data.lti_params.custom_resource_url.search(/\/teacher\b/)) {
				sdp(this, data, 'teacher_facing', true)
			} else {
				sdp(this, data, 'teacher_facing', false)
			}

		} else {
			// if not an LTI link, default value for agency_sanctioned is false and default value for teacher_facing is false
			sdp(this, data, 'agency_sanctioned', false)
			sdp(this, data, 'teacher_facing', false)
			// caveat: assessments are always teacher-facing
			if (this.type == 'assessment') this.teacher_facing = true
		}
		// resource is district-sanctioned if it's added in a LP

		// if resource is restricted to certain classes of users this will be set to a string that designates who's authorized
		sdp(this, data, 'restricted', '')

		// supplemental urls, e.g. for assessments (blueprints and paper copies)
		this.supp_links = []
		if (!empty(data.supp_links)) {
			for (let sl of data.supp_links) {
				let o = {}
				sdp(o, sl, 'description', '')
				sdp(o, sl, 'type', '')
				sdp(o, sl, 'url', '')
				this.supp_links.push(o)
			}
		}

		// temp things that we delete before saving
		sdp(this, data, 'full_resource_data_loaded', false)
		sdp(this, data, 'quick_look_showing', false)
		sdp(this, data, 'resource_showing', false)
		sdp(this, data, 'search_match', false)
		// we may retrieve a resource's lti_form from the get_resource_record service
		sdp(this, data, 'lti_form', '')
		sdp(this, data, 'tcc_folder_id', '')	// this can be set for tcc_folder_id items; we don't save it

		// properties that were used at one time but are no longer used, and should be removed to save space:
		// sdp(this, data, 'case_identifiers', [])	// replaced with "standards"
		// sdp(this, data, 'shareable', true)	// is this resource shareable? (not currently used for anything)
		// sdp(this, data, 'district_sanctioned', false)	// replaced with agency_sanctioned

	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		delete o.editing
		delete o.quick_look_showing
		delete o.resource_showing
		delete o.created_at
		delete o.lti_form
		delete o.full_resource_data_loaded
		delete o.search_match
		delete o.tcc_folder_id

		// set shareable and professional_development and todo to 1 or 0
		o.shareable = (this.shareable) ? 1 : 0
		o.todo = (this.todo) ? 1 : 0
		o.professional_development = (this.professional_development) ? 1 : 0

		// get savable copies for standards
		o.standards = []
		for (let standard of this.standards) {
			o.standards.push(standard.copy_for_save())
		}
		return o
	}

	// return the fontawesome icon class that should go with this resource type. this should be paired with class `fas`
	icon() {
		if (this.type == 'website') {
			// for google drive links, return generic document icon
			if (this.url.search(/(drive|docs)\.google/) > -1) return 'fa-file'
			let ext = this.url.replace(/.*?\.(\w+)$/, '$1')
			if (ext == 'pdf') return 'fa-file-pdf'
			if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			if (ext.indexOf('doc') == 0) return 'fa-file-word'
			if (ext.indexOf('ppt') == 0) return 'fa-file-powerpoint'
			return 'fa-link'
		}
		if (this.type == 'assessment') return 'fa-clipboard-list'	// list-alt / list-ol
		if (this.type == 'video' || this.type == 'interactive') return 'fa-video'
		if (this.type == 'sparkl') return 'fa-star'
		if (this.type == 'upload') {
			let ext = this.url.replace(/.*?\.(\w+)$/, '$1')
			if (ext == 'pdf') return 'fa-file-pdf'
			if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			if (ext.indexOf('doc') == 0) return 'fa-file-word'
			if (ext.indexOf('ppt') == 0) return 'fa-file-powerpoint'
		}
		if (this.type == 'html') return 'fa-file-alt'
		if (this.type == 'folder') return 'fa-folder'
		return 'fa-file'
	}

	type_label() {
		if (this.type == 'upload') return 'Uploaded File'
		if (this.type == 'html') return 'Text'
		if (this.type == 'website') return 'Website'
		if (this.type == 'document') return 'Document'
		if (this.type == 'video') return 'Video'
		if (this.type == 'interactive') return 'Interactive'
		if (this.type == 'safari') return 'Safari Resource'
		if (this.type == 'lti') return 'LTI Resource'
		if (this.type == 'resource_collection') return 'Resource Collection'
		if (this.type == 'collection_item') return 'Collection Item'
		if (this.type == 'assessment') return 'Assessment'
		if (this.type == 'sparkl') return 'Sparkl Activity'
		return ('unknown type')
	}

	has_openable_url() {
		// if we don't have a url obviously we can't open
		if (empty(resource.url)) return false

		// lti, assessment resources, and sparkl activities don't open directly as links
		if (resource.type == 'lti' || resource.type == 'assessment' || resource.type == 'sparkl') return false

		// if we get to here, assume the url is openable
		return true
	}

	// /course/41.0140/0
	full_url() {
		if (this.type == 'upload' || this.type == 'html') {
			// if the uploaded file or html has been sent to edelex, it will be a fully-qualified url
			if (this.url.includes('https:')) return this.url

			// otherwise construct from base
			let base = sr('$1//$2/user-files/', location.protocol, location.host)
			return base + this.url

		} else if (this.type == 'sparkl') {
			return `${vapp.$store.state.site_config.sparkl_origin}/${this.url}`

		} else if (!empty(this.url)) {
			return this.url

		} else {
			// if url field is empty, use /reslink/resource_id url form
			return `${window.location.origin}/reslink/${this.resource_id}`
		}
	}

	// return true iff this resource is visible by the given user role; if role not specified, use state role
	// note that callers of this fn should check themselves for whether or not the user is an editor for the collection, as collection editors can always see everything
	is_visible_by_user(role) {
		// if show_all_items_when_not_signed_in is 'yes', everyone gets to see everything
		if (vapp.site_config.show_all_items_when_not_signed_in == 'yes') return true

		// for lti/collection_item items, which we assume (at least for now) are publisher-supplied
		if (this.type == 'collection_item' || this.type == 'lti') {
			// don't show if not signed in
			if (!vapp.$store.getters.signed_in) return false
		}

		if (empty(role)) role = vapp.$store.getters.role

		// student-facing (non-teacher_facing) items are available to everyone
		if (!this.teacher_facing) return true

		// parents and students can't see anything else
		if (role == 'student' || role == 'parent') return false

		// the following restriction was moved to CollectionResourceFolder/CollectionUnit, because we need editors to be able to see these items
		// // items marked as leader_resource can only be viewed by admins and principals/assistant principals (not "regular" teachers)
		// if (this.lp_category == 'leader_resource') {
		// 	return (role == 'admin' || vapp.$store.getters.user_is_principal_or_ap)
		// }

		// if we get to here the user can see the resource
		return true
	}

	// return true IFF new_standards match (by identifier) the resource's current standards
	standards_match(new_standards) {
		// if both lengths are 0, we know they match; if the lengths differ, we know they don't match
		if (this.standards.length == 0 && new_standards.length == 0) return true
		if (this.standards.length != new_standards.length) return false

		// if we get to here the lengths are non-zero and match, so see if the same standards are in both arrays in the same order
		// (order might matter)
		for (let i = 0; i < this.standards.length; ++i) {
			// if any identifiers are different, no match
			if (this.standards[i].identifier != new_standards[i].identifier) return false
		}
		// if we get to here, match
		return true
	}

}
window.Resource = Resource

// standard sorting algorithm for resources: arr.sort(U.resources_sort)
U.resources_sort = function(a,b) {
	// teacher-facing on top
	if (a.teacher_facing && !b.teacher_facing) return -1
	if (b.teacher_facing && !a.teacher_facing) return 1

	// agency_sanctioned above non-agency_sanctioned
	if (a.agency_sanctioned && !b.agency_sanctioned) return -1
	if (b.agency_sanctioned && !a.agency_sanctioned) return 1

	// unit planning guides on top
	let a_planning = (a.description.search(/sample.unit/i) > -1 || a.description.search(/document.set/i) > -1) ? 1 : 0
	let b_planning = (b.description.search(/sample.unit/i) > -1 || b.description.search(/document.set/i) > -1) ? 1 : 0
	let dif = b_planning - a_planning
	if (dif != 0) return dif

	// for student resources, order by target_students: 'all', then 'ese', then 'adv'
	if (!a.teacher_facing && a.target_students != b.target_students) {
		if (a.target_students == 'all' && b.target_students != 'all') return -1
		if (b.target_students == 'all' && a.target_students != 'all') return 1
		// if we get here, neither a nor b is 'all', and they're different, so one must be ese and one must be adv

		if (a.target_students == 'adv') return -1
		else return 1
	}

	// if we get to here, order alphabetically by description
	if (a.description < b.description) return -1
	if (b.description < a.description) return 1
	return 0
}
